/*************************************/
/********** Site Styles ***********/
/*************************************/

body {
  background-color: #ffffff;
  color: #2e333f;
  height: calc(100vh);
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3 {
  font-weight: 600;
  text-transform: uppercase;
}

.App {
  text-align: center;
  height: calc(100vh);
}

/* Header */
header {
  background-color: #d58dde;
  background-image: linear-gradient(to bottom right ,#7c25cc, #da42e0);
  color: #ffffff;
  padding: 2%;
  height: 85px;
}

header h2 {
  text-transform: uppercase;
}

header img {
  max-width: 30px;
  margin: 0 20px 5px;
}

/* About Page */
.about-container {
  padding: 8%;
  text-align: left;
  background-color: #dad0db;
  height: calc(100vh - 85px);
}

.about-content {
  padding: 8%;
  border-radius: 20px;
  box-shadow: 5px 5px 5px rgba(60,60,60,0.2);
  background-color: #ffffff;
}

/* 404 Page */
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(80vh);
}

.not-found-container img {
  max-width: 250px;
}

/*************************************/
/********** Game Page ***********/
/*************************************/

#game-page-container {
  display: flex;
  justify-content: stretch;
  height: calc(100vh - 85px);
  position: relative;
}

/*************************************/
/********** Tools Panel ***********/
/*************************************/

#tools-panel-container {
  width: 60px;
  padding: 10px 0;
  background-color: #2b3141;
  z-index: 100;
}

.tools-button {
  font-size: 22px;
  cursor: pointer;
  padding: 15px;  
  color: #4ec8f1;
}

.tools-button.selected {
  background-color: rgba(0,0,0,0.5);
}

/* Content Flyout */ 
#content-flyout-container {
  position: absolute;
  top: 10px;
  left: 55px;
  width: calc(40vw);
  max-height: calc(70vh);
  background-color: #2b3141;
  color: #ffffff;
  border-radius: 0px 20px 20px 0px;
  text-align: left;
  border-right: 5px solid rgba(0,0,0,0.5);
  border-top: 5px solid rgba(0,0,0,0.5);
  border-bottom: 5px solid rgba(0,0,0,0.5);
  overflow: scroll;
  z-index: 100;
  -ms-overflow-style: none;
  scrollbar-width: none;  
}

#content-flyout-container::-webkit-scrollbar {
  display: none;
}

.context-header {
  color: #4ec8f1;
}

#content-flyout-inner-div {
  position: relative;
  padding: 3% 3% 3% 5%;
}

#close-flyout-button-container {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color:#4ec8f1;
  cursor: pointer;
}

.content-flyout-content li {
  color: #cccccc;
}

.settings-content-container h6 {
  margin-top: 30px;
}

.settings-content-container input {
  margin: 0 10px;
}

/*************************************/
/********** Board Panel ***********/
/*************************************/

#board-panel-container {
  padding: 25px;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#board-panel-container::-webkit-scrollbar {
  display: none;
}

/* Welcome view */
#welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(80vh);
}

#welcome-container img {
  max-width: 200px;
}

/* Game Play view */
#game-board-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(80vh);
}

#game-board {
  position: relative;
  overflow: scroll;
  height: 100%;
  margin: 30px auto 5px;
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

#game-board::-webkit-scrollbar {
  display: none;
}


#boardMap {
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
}

#winner-message-container {
  position: fixed;
  left: 0;
  top: 85px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  color: #ffffff;
  border-radius: 20px;
}

#winner-message-container img {
  max-width: 100px;
}

.new-high-score {
  text-align: left;
  margin-left: 30px;
}

.board-difficulty {
  position: absolute;
  top: 20px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
}

.board-square-container {
  display: inline;
  min-width: 30px;
}
.board-square-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.board-square-value {
  max-width: 30px;
  font-weight: 600;
}

.board-square-value.color-0 {
  color: transparent;
}

.board-square-value.color-1 {
  color: #cc66db;
}

.board-square-value.color-2 {
  color: #4ec8f1;
}

.board-square-value.color-3 {
  color: #1a083f;
}

.board-square-value.color-4 {
  color: #236ff6;
}

.board-square-value.color-5 {
  color: #7c25cc;
}

.board-square-value.color-6 {
  color: #99a9d1;
}

.board-square-value.color-7 {
  color: #f2c206;
}

.board-square-value.color-8 {
  color: #2b3141; 
}

.board-row {
  display: flex;
  justify-content: center;
}

.board-square-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  float:left;
  text-align:center;
  border-radius: 10px;
  border: 1px solid #ffffff;
  background-color: #d3e1e5/*#c7e7f2;*/
}

.board-square-inner.covered {
  background-color: #2b3141;
  border: 1px solid white;
  cursor: pointer;
  
}

.board-square-inner img {
  padding: 4px;
}

/*************************************/
/********** Progress Panel ***********/
/*************************************/

#progress-panel-container {
  width: 25%;
  padding: 20px;
  background-color: #2b3141;
  z-index: 100;
}

/* New Game button */
#reset-button-container {
  margin: 20px 0;
}

/* Game clock and Bomb Counter */
#game-clock-container, #bomb-counter-container {
  color: white;
  text-align: left;
  margin: 10px 0;
}

.game-clock, .bomb-counter {
  background-color: black;
  border-radius: 20px;
  padding: 10px;
  text-align:center;
  color: #d58dde;
  margin: 5px 0 20px;
}

@media screen and (max-width: 600px) {
  #game-page-container {
    flex-direction: column-reverse;
  }

  #tools-panel-container {
    display: flex;
    width: 100%;
  }

  #progress-panel-container {
    display: flex;
    width: 100%;
  }

  #reset-button-container {
    margin: 0 10px 0 0;
  }

  #game-clock-container #bomb-counter-container {
    margin: 0 5px;
  }

  .progress-label {
    display: none;
  }

  .game-clock, .bomb-counter {
    margin: 0 5px;
    width: 100px;
  }

  #game-clock-container, #bomb-counter-container {
    margin: 0;
  }

  #tools-panel-container {
    padding: 0;
  }

  #content-flyout-container {
    top: 150px;
    left: 0px;
    width: 100%;
    border: 5px solid rgba(0,0,0,0.5);
    border-radius: 20px; 
  }

  #welcome-container {
    height: calc(65vh);
  }

  .board-difficulty {
    top: 80px;
  }

  #game-board {
    height: calc(70vh);
  }
}